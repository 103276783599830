import React from 'react';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import Heading from 'common/src/components/Heading';
import Text from 'common/src/components/Text';
import SectionWrapper, { Container, ImageWrapper, TextWrapper } from './aboutUs.style';

const ArcherRXAboutUs = () => {
  const data = useStaticQuery(graphql`
    query {
      image1: file(
        relativePath: { eq: "image/archerhealth/feature/ArcherRx-Increased-Medication-Adherence-min.png" }        
      ) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
      }
    }
    `);
  
  const setTitle = title => {
    return { __html: title };
  };


  return (
    <SectionWrapper id="aboutUs">
      <Container>
        
        <TextWrapper>
          <Fade right>
            <Heading as="h1" dangerouslySetInnerHTML={setTitle(`Increased Medication Adherence`)} />
            <Text as="h2" content={`50% of medications for chronic diseases are not taken as prescribed.`} />
            <Text content={`Poor medication adherence increases the risk of 
            significant, life-threatening health concerns. 
            Your onsite pharmacy will not only provide employees with a 
            convenient way to fill prescriptions, but it'll also provide 
            them with direct access to care management education and 
            pharmacist-facilitated counseling.`} />
          </Fade>
        </TextWrapper>
        <ImageWrapper>
          <Fade left>
          <Image
                  fluid={
                    (data.image1 !== null) | undefined
                      ? data.image1.childImageSharp.fluid
                      : {}
                  }
                  className="carousel_img"
                  alt="Feature Image 1"
                />
          </Fade>
        </ImageWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default ArcherRXAboutUs;
