import styled from 'styled-components';

const FeatureSectionWrapper = styled.section`
  padding: 80px 0 100px;

  @media (max-width: 1440px) {
    padding: 40px 0 50px;
  }

  @media (max-width: 768px) {
    padding: 40px 0 0px;
    div.col {
      margin-bottom: 30px;
    }
  }
  
  @media (max-width: 500px) {
    padding: 30px 0;
  }

  #featured-col-1 {    
    border: 4px solid #F597004D;

    h2 {
      color: #F7982C;
      font-size: 30px;
      font-weight: 700;
    }
    p {
      color: #3D636C;
      font-weight: 500;
      font-size: 17px;
    }
  }

  #featured-col-2 {    
    border: 4px solid #3362FF4D;

    @media screen and (min-width: 768px) {
      margin: 0 20px;
    }

    h2 {
      color: #4F70DF;
      font-size: 60px;
      font-weight: 700;
    }
    p {
      color: #3D636C;
      font-weight: 500;
      font-size: 20px;
    }
  }

  #featured-col-3 {    
    border: 4px solid #2CC36B4A;

    h2 {
      color: #2CC36B;
      font-size: 30px;
      font-weight: 700;
    }
    p {
      color: #3D636C;
      font-weight: 500;
      font-size: 17px;
    }
  }

  .feature__block {

    box-shadow: 0px 8px 20px #00000015;
    border-radius: 10px;
    padding: 50px 30px;

    position: relative;
    height: 100%;
    transition: box-shadow 0.3s ease;
    .icon__wrapper {
      position: relative;
      background: transperent;
      .flaticon-flask {
        &:before {
          margin-left: 8px;
        }
      }

      &:before {
        transform: rotate(45deg);
        background-color: rgba(255, 255, 255, 0.15);
      }
      &:after {
        transform: rotate(-45deg);
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
    &:hover {
      box-shadow: 0 40px 90px -30px rgba(39, 79, 117, 0.2);
      cursor: pointer;
    }
  }

  .row {
    > .col {
      &:nth-child(1) {
        .feature__block {
          .icon__wrapper {
            color: #29cf8a;
            transition: all 0.6s ease;
          }
        }
        &:hover {
          .feature__block {
            .icon__wrapper {
              background: #29cf8a;
              color: #fff;
              border: 0;
            }
          }
        }
      }
      &:nth-child(2) {
        .feature__block {
          .icon__wrapper {
            color: #ff86ab;
            transition: all 0.6s ease;
          }
        }
        &:hover {
          .feature__block {
            .icon__wrapper {
              background: #ff86ab;
              color: #fff;
              border: 0;
            }
          }
        }
      }
      &:nth-child(3) {
        .feature__block {
          .icon__wrapper {
            color: #ff9000;
            transition: all 0.6s ease;
          }
        }
      }
      &:hover {
        .feature__block {
          .icon__wrapper {
            background: #ff9000;
            color: #fff;
          }
        }
      }
    }
  }
`;

export default FeatureSectionWrapper;
