import React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from 'common/src/theme/appclassic';
import { ResetCSS } from 'common/src/assets/css/style';
import Sticky from 'react-stickynode';
import Navbar from '../containers/AppClassic/Navbar';
import Banner from '../containers/Agency/ArcherRxBanner';
import Footer from '../containers/Agency/Footer';
import GlobalStyle, {
  AppWrapper,
  ContentWrapper,
} from '../containers/AppClassic/appClassic.style';

import SEO from '../components/seo';

import ArcherRXFeatureSection from '../containers/AppClassic/ArcherRxFeatureSection';
import ArcherRXDashboardFeatures from '../containers/AppModern/ArcherRxDashboardFeatures';
import ArcherRXAboutUs from '../containers/Interior/ArcherRxAboutUs';
import ArcherRXIntegratedHealthcareServices from '../containers/AppModern/ArcherRxIntegratedHealthcareServices';
import Newsletter from '../containers/Agency/Newsletter';
import Container from 'common/src/components/UI/Container';
import Heading from 'common/src/components/Heading';

export default function() {
  return (
    <ThemeProvider theme={theme}>
      <>
        <SEO title="Archer RX Onsite Pharmacy Services" description="Save your employees time and money with an onsite pharmacy. Offering medication dispensing, medication counseling & easy refills. Contact us today to get started!" />
        <ResetCSS />
        <GlobalStyle />

        <AppWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-active">
            <Navbar />
          </Sticky>
          <ContentWrapper>
            {/* ArcherRx */}
            <Banner />
            <ArcherRXFeatureSection />
            <ArcherRXDashboardFeatures />
            <ArcherRXAboutUs />
            <ArcherRXIntegratedHealthcareServices />
            <Container>
            <div id="article-content">
            <Heading as="h2" content="Why You Need an Onsite Pharmacy"/>
            <p>Everyone knows that waiting for prescriptions or trying to get refills can be among the more time-consuming and frustrating parts of taking care of your health. That’s why we offer Archer Rx -- our onsite pharmacy care option that lets you give your employees convenient access to their medication. </p>

<p>Traveling to a pharmacy during work hours may be inconvenient for your employees, and it often means they will need to take time off from work to do so. Here are some reasons why an onsite pharmacy is a good idea that benefits employers and employees alike. </p>
<br/><br/><br/>
            <Heading as="h2" content="The Benefits of an Onsite Pharmacy"/>
            <p>An onsite pharmacy offers plenty of benefits to your employees: </p>
            <ul>
<li>&bull; More convenient way for your employees to get their prescription drugs.</li> 
<li>&bull; Your employees will also have an easier time sticking to their medication schedule for a chronic condition.</li> 
<li>&bull; Your pharmacy team can build relationships with your employees. </li>
<li>&bull; And finally, you'll get more patient-centered care, which often leads to better outcomes.</li>
</ul>
<p>If you choose to have an onsite pharmacy at your location, you and your employees can benefit from various pharmacy services. For example, your employees can see the side effects of specialty medication quickly. They can also have their prescriptions filled while working instead of having to leave the premises and stand in line at a pharmacy.</p>

            <br/><br/><br/>
            <Heading as="h2" content="A Healthier Workforce"/>
            <p>
            When you pair onsite health clinic services with onsite pharmacy services, it’s easier to have healthier and happier employees. Not only do these benefits help with employee retention, but can also help you attract new employees.
            </p>
            </div>
            </Container>
            <Newsletter />
            <Footer />
          </ContentWrapper>
        </AppWrapper>
      </>
    </ThemeProvider>
  );
}
